@use './variables' as *; 

@mixin tablet {
    @media screen and (min-width: $breakpoint-tablet) {
      @content;
    }
  } 

  @mixin desktop {
    @media screen and (min-width: $breakpoint-desktop) {
      @content;
    }
  }
  @mixin mobile {
    @media screen and (max-width: 767px) {
      @content;
    }
  }
  @mixin themeBuilder {
    &Red{
        background-color: $themeRed;
    }
    &Blue{
        background-color: $themeBlue;
    }
    &Sky{
        background-color: $themeSky;
    }
    &Purple{
        background-color: $themePurple;
    }
    &Green{
        background-color: $themeGreen;
    }
    &Grey{
        background-color: $themeGrey;
      }
  }
  @mixin themeText {
    &Red{
        color: $themeRed;
    }
    &Blue{
        color: $themeBlue;
    }
    &Sky{
        color: $themeSky;
    }
    &Purple{
        color: $themePurple;
    }
    &Green{
        color: $themeGreen;
    }
    &Grey{
        color: $themeGrey;
    }
  }
  