@use '../../tools/partials/mixins' as *;
@use '../../tools/partials/variables' as *;

.section {
  font-family: body;
  @include tablet {
    flex-direction: row;
    width: 100%;
    margin: 0rem 0 8rem 0;
  }
  &_header {
    &Red,
    &Blue,
    &Sky,
    &Purple,
    &Green,
    &Grey {
      margin: 0;
      position: relative;
      padding: 1rem 0 0rem 1rem;
      width: 100%;
      right: 1rem;
      align-self: center;
      font-family: head;
      font-size: 1.5rem;
      color: $themeRed;
    }
    @include themeText;
  }
  &_subheader {
    &Red,
    &Blue,
    &Sky,
    &Purple,
    &Green,
    &Grey {
      font-family: head;
      color: $themeRed;
      margin-right: 1rem;
    }
    @include themeText;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  &Images {
    width: calc(100% + 2rem);
    display: flex;
    flex-direction: column;
    align-content: center;
    @include tablet {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &Image {
    max-height: 600px;
    width: 100%;
    align-self: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    &:hover {
      transform: scale(1.02);
      transition: 300ms;
      z-index: 5;
    }
    @include tablet {
      width: 450px;
      border-radius: 6px;
      height: 700px;
    }

    &Wrapper {
      align-self: center;
      @include tablet {
        display: flex;
        justify-content: flex-end;
        padding-top: 1rem;
        margin-left: 2rem;
      }
    }
  }
  &_paragraph {
    font-family: body;
    @include tablet {
      font-size: 1.1rem;
    }
    &-photo {
      max-width: 80%;
      margin: 0;
      border-radius: 10px;
      max-height: 500px;
    }
    &-image {
      margin: 0;
      @include tablet {
        width: 45%;
      }
      &--about {
        margin: 0;
        justify-content: center;
        @include tablet {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 1rem;
        }
      }
    }
    &Wrapper {
      @include tablet {
        margin-bottom: 2rem;
        margin-top: 2rem;
      }
    }
    &-caption {
      &Red,
      &Blue,
      &Sky,
      &Purple,
      &Green,
      &Grey {
        margin: 0 0 2rem 0;
        font-family: head;
        color: $themeRed;
        font-size: 0.8rem;
        line-height: 1.2;
        @include tablet {
          font-size: 1rem;
          width: 90%;
          text-align: right;
        }
      }
      @include themeText;
    }
  }
  &_text {
    align-self: flex-start;
    @include tablet {
      width: 50%;
    }
  }
}
#Skills {
  @include tablet {
    flex-direction: column;
  }
  @include desktop {
    flex-direction: row;
  }
}

#logo {
  height: 100px;
  max-width: 300px;
  margin-right: 1.5rem;
  box-shadow: none;
  &:last-child {
    margin-right: 0rem;
  }
}
.sectionLinkdark {
  color: rgb(50, 148, 205);
  &Contact {
    color: rgb(50, 148, 205);
  }
}
.sectionLink {
  color: rgb(50, 148, 205);
  &Contact {
    color: whitesmoke;
  }
}
.photogrid__photo {
  width: 100%;
  max-height: 500px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }
}
.photoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% / 3);
  background-color: black;
  margin: 0;
  border-radius: 6px;
}
.Gallery {
  &__photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100%;
    width: calc(100% + 2rem);
    position: relative;
    right: 1rem;
  }
  &__selector {
    display: flex;
    align-items: center;
  }
}
.mainSection {
  &Arrow {
    max-height: 1.5rem;
  }
}
.largeView {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 700px;
    min-height: 200px;
    &-background {
      width: 80%;
      height: 80%;
      margin: 0rem;
      display: flex;
      justify-content: space-between;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1200px;
      position: relative;
    }
    &-photo {
      max-width: 100%;
      z-index: 10;
      position: relative;
      overflow-y: scroll;
      max-height: 95vh;
      margin: 0 2px;
      @include tablet {
        margin: 0 2rem;
      }
    }
  }
  &__close {
    &-icon {
      position: absolute;
      top: 10px;
      left: 90vw;
      z-index: 15;
      transition: 300ms;
      &:hover {
        transform: scale(1.2);
        transition: 300ms;
      }
      @include mobile {
        top: 80px;
      }
    }
  }
}
#section {
  font-family: body;
}
#logos,
#logosDark {
  display: flex;
  overflow: scroll;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 6px;
  flex-wrap: nowrap;
  @include desktop {
    margin-bottom: 0;
    box-shadow: 0;
  }
}
#logosDark {
  background-color: rgba(167, 164, 164, 0.959);
}
.Gallery {
  box-sizing: border-box;
  position: relative;
}

#skills {
  @include tablet {
    flex-direction: column;
  }
  @include desktop {
    flex-direction: row;
  }
}
#skillsText {
  @include tablet {
    align-self: flex-start;
    width: 80%;
  }
  @include desktop {
    width: 45%;
  }
}
#about {
  @include tablet {
    &_image2 {
      align-self: flex-start;
    }
  }
  &Text {
    @include tablet {
      height: 70%;
    }
  }
}
.homeImageWrapper {
  width: 30%;
}
#Gallery {
  flex-direction: column;
  &Images {
    justify-content: center;
  }
  &Pic {
    @include tablet {
      height: auto;
      width: 45%;
      height: auto;
      margin: 0 1rem 1rem 0rem;
    }
  }
}
#Contact {
  &Red,
  &Blue,
  &Sky,
  &Purple,
  &Green,
  &Grey {
    width: 100%;
    align-self: center;
    position: relative;
    right: 1rem;
    padding: 0 0 2rem 2rem;
    color: ivory;
    font-family: head;
    margin-bottom: 0;
    position: relative;
    top: 2rem;
    @include desktop {
      padding: 100px 1rem;
    }
  }
  @include themeBuilder;
  &Header {
    color: ivory;
  }
}

#contactLogo {
  height: auto;
  width: 90px;
  box-shadow: none;
  &:last-child {
    height: 25px;
    @include tablet {
      height: 55px;
    }
  }
  &:first-child {
    height: 35px;
    @include tablet {
      height: 75px;
    }
  }
  @include tablet {
    width: 200px;
  }
  &s {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    box-shadow: none;
  }
}
#Bio {
  margin: 0;
  @include tablet {
    flex-direction: column;
  }
}
#University {
  @include tablet {
    flex-direction: column;
  }
}
#about {
  &Paragraph {
    font-family: body;
    line-height: 1.5;
    &Logo {
      line-height: 1.5;
      @include desktop {
        float: left;
        width: 45%;
      }
    }
    @include tablet {
      width: 45%;
      float: left;
      margin-top: 0cm;
    }
    &Middle {
      line-height: 1.5;
      @include tablet {
        float: right;
        width: 50%;
        margin-top: 0cm;
      }
    }
  }
  &Image {
    width: 100%;
    position: relative;
    @include tablet {
      width: 95%;
      min-height: 300px;
      height: auto;
      align-self: flex-end;
      max-width: 600px;
      right: 0rem;
      &Logo,
      &LogoDark {
        height: auto;
        width: 60vw;
        max-width: 700px;
        margin: 2rem 0rem;
        @include desktop {
          width: 90%;
        }
      }
      &LogoDark {
        background-color: $darkModeAccent;
      }
    }
  }
  &ImageMiddle {
    @include tablet {
      width: 95%;
      min-height: 300px;
      height: auto;
      max-width: 600px;
    }
  }
}
#middleImage {
  @include tablet {
    float: left;
    display: inline;
    width: 50%;
  }
}
#brainstation {
  @include tablet {
    flex-direction: column;
  }
  @include desktop {
    display: inline-block;
  }
}
#homeskills {
  &Display {
    width: auto;
    height: 70px margin 1rem 2rem;
  }
  &Other {
    align-items: flex-start;
  }
}
#programingLanguages,
#programingLanguagesDark {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  flex-wrap: nowrap;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  margin: 2rem auto;
  border-radius: 8px;
  height: 55px;
  align-items: center;
  overflow-y: hidden;
  padding: 1rem;
  width: 95%;
  max-width: 990px;
  position: relative;
  right: 0.4rem;
  @include desktop {
    margin: 0 auto;
    right: 0;
  }
}
#programingLanguagesDark {
  background-color: $darkModeAccent;
}
.skills {
  &LargeView {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 750px;
    margin: 3rem auto;
    &Link {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
    @include desktop {
      flex-direction: row;
      max-width: none;
    }
    &Image {
      width: 85%;
      margin: 0 1rem;
      max-width: 500px;
      height: 120px;
      @include tablet {
        height: 150px;
        width: auto;
      }
    }
    &_selector,
    &_selectorDark {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include desktop {
        width: 70%;
        margin-right: 2rem;
      }
    }
    &_selectorDark {
      background-color: $darkModeAccent;
      padding: 0 8px;
      border-radius: 10px;
    }
  }
  &_list {
    list-style-type: circle;
  }
}
.scrollArrow {
  &Left,
  &Right {
    width: 30px;
    height: 30px;
  }
  &Right {
    transform: rotate(-180deg);
  }
  &Background {
    &Red,
    &Blue,
    &Sky,
    &Purple,
    &Green,
    &Grey {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      border-radius: 100%;
      justify-content: center;
      border: 2px solid black;
    }
    @include themeBuilder;
    &:hover {
      transform: scale(1.1);
      transition: 100ms;
    }
  }
}
.Gallery {
  &__selector {
    margin-top: 1rem;
    &-box {
      max-width: 0;
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.25s, max-width 0.25s;
      font-family: head;
      color: white;
      padding: 0;
      &Active {
        max-height: 400px;
        transition: max-height 0.25s, max-width 0.25s;
        border-radius: 5px;
        font-family: head;
        color: white;
        padding: 0;
        width: 60%;
        max-width: 300px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      }
      &--list {
        margin: 0;
        padding: 0;
      }
      &--item {
        &Red,
        &Blue,
        &Sky,
        &Purple,
        &Green,
        &Grey {
          padding: 0.5rem 0rem;
          border-bottom: 1px solid white;
          width: 100%;
          padding-left: 1rem;
          transition: 0.3s;
          cursor: pointer;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            transform: scale(1.1);
            transition: 0.3s;
            text-decoration: underline;
          }
        }
        @include themeBuilder;
      }
    }
  }
}
#gallery {
  &Header {
    margin: 0;
    font-size: 20px;
    width: fit-content;
    padding-top: 0;
  }
  &ArrowBackground {
    width: 20px;
    height: 20px;
    border: 2px solid black;
    margin: auto 0;
    &Large {
      &Red,
      &Blue,
      &Sky,
      &Purple,
      &Green,
      &Grey {
        height: 2rem;
        width: 2rem;
        border-radius: 100%;
        border: 2px solid black;
        @include tablet {
          height: 3rem;
          width: 3rem;
        }
      }
      @include themeBuilder;
    }
  }
  &Arrow {
    transform: rotate(-90deg);
    transition: 0.4s;
    &Expanded {
      transform: rotate(90deg);
      position: relative;
      bottom: 2px;
      transition: 0.4s;
    }
    &Left,
    &Right {
      position: relative;
    }
    &Right {
      transform: rotate(-180deg);
    }
  }
}
.Contact {
  &_methods {
    @include tablet {
      display: flex;
      margin: 1rem auto 0 auto;
      justify-content: space-between;
      max-width: 1050px;
    }
    &-section {
      &Red,
      &Blue,
      &Sky,
      &Purple,
      &Green,
      &Grey {
        border-radius: 5px;
        padding: 8px;
        margin-bottom: 1rem;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        max-width: 350px;
        @include mobile {
          margin: 1rem auto;
        }
        &:first-child {
          margin-top: 1rem;
        }
        transition: 250ms;
        &:hover {
          transform: scale(1.08);
          transition: 250ms;
        }
        @include tablet {
          width: 30%;
          &:first-child {
            margin-top: 0rem;
          }
        }
        @include desktop {
          height: 150px;
          border-radius: 10px;
          display: flex;
          align-items: center;
        }
      }
      @include themeBuilder;
      &--line1 {
        color: whitesmoke;
        font-family: head;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        &---image {
          max-width: 200px;
          height: 1.5rem;
          margin-right: 5px;
        }
      }
      &--header {
        margin: 5px 0;
      }
      &--text {
        margin: 5px 0;
        color: whitesmoke;
        font-weight: bold;
        &:hover {
          text-decoration: underline;
        }
        @include tablet {
          overflow-x: scroll;
          margin: 5px auto;
          color: whitesmoke;
          font-weight: bolder;
        }
      }
    }
  }
  &Link {
    text-decoration: inherit;
    color: whitesmoke;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
.icon{
  max-height: 70px; margin-right: 30px;
}