$breakpoint-tablet: 768px;
$breakpoint-large-tablet: 1068px;
$breakpoint-desktop: 1280px;

$themeRed: #a80000d3;
$themePurple: #52317ed3; 
$themeGreen: #49a086d3;
$themeBlue: #3220d4d3;
$themeGrey: #bbb2b2d3;
$themeSky: #31caecd3;
$darkModeAccent: rgba(167, 164, 164, 0.959);

@font-face{ 
    src: url(../fonts/SecularOne-Regular.ttf); 
    font-family: head;
} 
@font-face{ 
    src: url(../fonts/Roboto-Thin.ttf);
    font-family: body;
} 
@font-face{ 
    src: url(../fonts/Roboto-Regular.ttf);
    font-family: body_header;
}